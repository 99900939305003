import React, { useState, useEffect } from "react";
import axios from "axios";
import FileBase64 from "react-file-base64";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card } from ".";
import { ScrollView } from "@cantonjs/react-scroll-view";

function Admin() {
  const domain = "https://rbodenwoodturning.com";
  //const domain = "http://localhost:3001";

  const [adminPassword, setAdminPassword] = useState("");
  const [correctPassword, setCorrectPassword] = useState(false);
  const [adminName, setAdminName] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesGallery, setImagesGallery] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [siteData, setSiteData] = useState([]);
  const [title, setTitle] = useState("");
  const [itemId, setItemId] = useState("");
  const [price, setPrice] = useState("");
  const [photo, setPhoto] = useState("");
  const [subtext, setSubtext] = useState("");
  const [woodtype, setWoodType] = useState("");
  const [style, setStyle] = useState("");
  const [titleEdit, setTitleEdit] = useState("");
  const [itemIdEdit, setItemIdEdit] = useState("");
  const [priceEdit, setPriceEdit] = useState("");
  const [photoEdit, setPhotoEdit] = useState("");
  const [subtextEdit, setSubtextEdit] = useState("");
  const [woodtypeEdit, setWoodTypeEdit] = useState("");
  const [styleEdit, setStyleEdit] = useState("");
  const [currentItem, setCurrentItem] = useState("");
  const [dataLocation, setDataLocation] = useState("");
  const [dataLocationEdit, setDataLocationEdit] = useState("");
  const [checked, setChecked] = useState(false);

  const resetText = (item) => {
    setTitleEdit(item.title);
    setItemIdEdit(item.title);
    setStyleEdit(item.style);
    setPriceEdit(item.price);
    setWoodTypeEdit(item.woodtype);
    setSubtextEdit(item.subtext);
    setPhotoEdit(item.filePath);
    setDataLocationEdit(item.location);
    setItemIdEdit(item.itemId);
    if (item.location === "Gallery") {
      setChecked(true);
    } else if (item.location === "Shop") {
      setChecked(false);
    }
    setCurrentItem(item);
  };

  const filterCards = (image) => {
    return (
      <>
        {/* Start of modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h4
                  className="modal-title text-center"
                  id="exampleModalLongTitle"
                >
                  Edit Item
                </h4>
                <div className="form">
                  <form>
                    <div className="creatListingModal">
                      <label>Title</label>
                      <input
                        type="text"
                        value={titleEdit}
                        onChange={(event) => setTitleEdit(event.target.value)}
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Guitar Lessons"
                        maxLength="50"
                        required
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label className="mr-4">Photo</label>
                      <FileBase64
                        className="form-control"
                        multiple={false}
                        onDone={(base64) => setPhotoEdit(base64)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Price</label>
                      <input
                        type="number"
                        value={priceEdit}
                        onChange={(event) => setPriceEdit(event.target.value)}
                        className="form-control"
                        id="price"
                        name="price"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Wood Type</label>
                      <input
                        type="text"
                        value={woodtypeEdit}
                        onChange={(event) =>
                          setWoodTypeEdit(event.target.value)
                        }
                        className="form-control"
                        id="price"
                        name="price"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Wood Style</label>
                      <select
                        className="ml-3"
                        list="datalistOptions"
                        id="exampleDataList"
                        value={styleEdit}
                        onChange={(event) => setStyleEdit(event.target.value)}
                        required
                      >
                        <option> -- select an option -- </option>
                        {siteData[0].styles.map((style) => (
                          <option value={style}>{style}</option>
                        ))}
                      </select>
                      <div className="form-group">
                        <label>Item Id</label>
                        <input
                          type="number"
                          value={itemIdEdit}
                          onChange={(event) => setItemIdEdit(event.target.value)}
                          className="form-control"
                          id="id"
                          name="id"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        type="text"
                        value={subtextEdit}
                        onChange={(event) => setSubtextEdit(event.target.value)}
                        className="form-control descriptionInput"
                        id="description"
                        name="description"
                        required
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  data-dismiss="modal"
                  onClick={() => deleteItem()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => editItem()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card
          image={image.filePath.base64}
          title={image.title}
          subtext={image.subtext}
          button={
            <button
              className="btn btn-primary btn-sm m-3"
              data-toggle="modal"
              data-target="#editModal"
              href="#"
              onClick={() => resetText(image)}
            >
              Edit Item
            </button>
          }
        />
      </>
    );
  };

  const deleteItem = () => {
    // delete item
    axios
      .post(`${domain}/deleteItem`, {
        headers: { "Content-Type": "application/json" },
        currentItem,
        location: dataLocationEdit,
      })
      .then((res) => {
        if (res.data.success) {
          new toast.success(currentItem.title + " was deleted");
          setImages(
            images.filter((imageList) => imageList.title !== currentItem.title)
          );
          setImagesGallery(
            imagesGallery.filter(
              (imageList) => imageList.title !== currentItem.title
            )
          );
        }
      })
      .catch((res) => {
        new toast.error("Error with deleting item");
      });
  };

  const editItem = () => {
    const editedItem = {
      title: titleEdit,
      price: priceEdit,
      subtext: subtextEdit,
      woodtype: woodtypeEdit,
      style: styleEdit,
      filePath: photoEdit,
      location: dataLocationEdit,
      itemId: itemIdEdit
    };
    
    // give old item and new item
    if (
      titleEdit === "" ||
      photoEdit === "" ||
      subtextEdit === "" ||
      woodtypeEdit === "" ||
      styleEdit === "-- select an option --" ||
      itemIdEdit === ""
    ) {
      new toast.info(
        "You are missing a detail feild, check to make sure all inputs are filled. (Price doesnt need to be filled)"
      );
    } else {
      axios
        .post(`${domain}/editItem`, {
          headers: { "Content-Type": "application/json" },
          editedItem,
          title: currentItem.title,
          filePath: currentItem.filePath,
          location: dataLocationEdit,
        })
        .then((res) => {
          if (res.data.success) {
            new toast.success(currentItem.title + " was edited");
          }
        })
        .catch((res) => {
          new toast.error("Error with editing item");
        });
    }
  };

  useEffect(() => {
    axios
      .post(`${domain}/getGallery`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.success) {
          setImagesGallery(res.data.galleryItems);
        }
      })
      .catch((res) => {
        new toast.error("Error with getting gallery");
      });

    axios
      .post(`${domain}/getShop`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.success) {
          setImages(res.data.shopItems);
        }
      })
      .catch((res) => {
        new toast.error("Error with getting gallery");
      });

    setTimeout(() => {
      setIsLoaded(true);
    }, 10000);

    axios
      .post(`${domain}/getSiteData`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.success) {
          setSiteData(res.data.items);
        }
      })
      .catch((res) => {
        new toast.error("Error with getting site data");
      });
  }, [isLoaded]);

  const submitPassword = () => {
    axios
      .post(`${domain}/findAdmin`, {
        headers: { "Content-Type": "application/json" },
        code: adminPassword,
      })
      .then((res) => {
        if (res.data.success) {
          setAdminName(res.data.user.name);
          setCorrectPassword(true);
        
          new toast.success("Logged in Successfully");
        }
      })
      .catch((res) => {
        new toast.error("wrong password, or error with the database");
      });
  };

  const addData = () => {
    const item = {
      title: title,
      filePath: photo,
      subtext: subtext,
      woodtype: woodtype,
      style: style,
      price: price,
      location: dataLocation,
      itemId: itemId
    };
    if (
      title === "" ||
      photo === "" ||
      subtext === "" ||
      subtext === "" ||
      woodtype === "" ||
      style === "-- select an option --" ||
      dataLocation === "" ||
      itemId === ""
    ) {
      new toast.info(
        "You are missing a detail feild, check to make sure all inputs are filled. (Price doesnt need to be filled)"
      );
    } else {
      axios
        .post(`${domain}/upload${dataLocation}Item`, {
          headers: { "Content-Type": "application/json" },
          item: item,
        })
        .then((res) => {
          new toast.success(
            "Information was successfully sent to the database"
          );
          if (dataLocation === "Gallery") {
            setImagesGallery((imagesGallery) => [...imagesGallery, item]);
          } else if (dataLocation === "Shop") {
            setImages((images) => [...images, item]);
          }
          setTitle("");
          setPhoto("");
          setSubtext("");
          setWoodType("");
          setStyle("");
          setPrice("");
          setItemId("");
        })
        .catch((res) => {
          new toast.error(
            "Something went wrong with uploading the information, contact Ammon"
          );
        });
    }
  };
  return (
    <>
      <div className="admin text-center">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          draggable
          pauseOnHover
        />
        {correctPassword ? (
          // data entry page
          <div className="adminInner">
            <h1>Welcome {adminName}</h1>
            {/* Put code to add more data */}
            <div className="row justify-content-center">
              <div className="col-md-9 mb-md-0 mb-5">
                <form
                  id="contact-form"
                  name="contact-form"
                  enctype="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="md-form mb-0">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <label htmlFor="name" className="">
                          Title
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="md-form mb-0">
                        <input
                          type="number"
                          id="email"
                          name="email"
                          className="form-control"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                        <label htmlFor="email" className="">
                          Price
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="md-form mb-0">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={woodtype}
                          onChange={(e) => setWoodType(e.target.value)}
                        />
                        <label htmlFor="name" className="">
                          Wood Type
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          value={itemId}
                          onChange={(event) => setItemId(event.target.value)}
                          className="form-control"
                          id="id"
                          name="id"
                          required
                        />
                        <label>Item Id</label>
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="md-form mb-0">
                        <label htmlFor="email" className="">
                          Wood Style
                        </label>
                        <select
                          className="ml-3"
                          list="datalistOptions"
                          id="exampleDataList"
                          value={style}
                          onChange={(event) => setStyle(event.target.value)}
                          required
                        >
                          <option> -- select an option -- </option>
                          {siteData[0].styles.map((style) => (
                            <option value={style}>{style}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="md-form mb-0">
                        <FileBase64
                          multiple={false}
                          onDone={(base64) => setPhoto(base64)}
                        />
                        <label htmlFor="email" className="">
                          Photo
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="md-form mb-0">
                        <input
                          type="radio"
                          id="gallery"
                          name="page"
                          value="Gallery"
                          onChange={(event) =>
                            setDataLocation(event.target.value)
                          }
                        />
                         {" "}
                        <label className="mr-4" for="html">
                          Gallery
                        </label>
                        <input
                          type="radio"
                          id="shop"
                          name="page"
                          value="Shop"
                          onChange={(event) =>
                            setDataLocation(event.target.value)
                          }
                        />
                         <label for="css">Shop</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="md-form">
                        <textarea
                          type="text"
                          id="message"
                          name="message"
                          rows="2"
                          className="form-control md-textarea resize"
                          value={subtext}
                          onChange={(e) => setSubtext(e.target.value)}
                        ></textarea>
                        <label htmlFor="message">Description</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <button className="btn btn-primary" onClick={() => addData()}>
              Add Item
            </button>

            <details className="mt-4">
              <summary>Edit Items</summary>
              <h2 className="mt-2">Shop Items ({images.length})</h2>
              {images.length === 0 && !isLoaded ? (
                <>
                  <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <ScrollView isHorizontal={true} className="mt-4">
                  <div className="mx-4 row flex-nowrap gx-4 gx-lg-4 row-cols-1 row-cols-sm-3 row-cols-md-2 row-cols-xl-6 row-cols-lg-2">
                    {images.map(filterCards)}
                  </div>
                </ScrollView>
              )}
              <h2 className="mt-2">Gallery Items ({imagesGallery.length})</h2>
              {imagesGallery.length === 0 ? (
                <>
                  <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <ScrollView isHorizontal={true} className="mt-4">
                  <div className="mx-4 row flex-nowrap gx-4 gx-lg-4 row-cols-1 row-cols-sm-3 row-cols-md-2 row-cols-xl-6 row-cols-lg-2">
                    {imagesGallery.map(filterCards)}
                  </div>
                </ScrollView>
              )}
            </details>
          </div>
        ) : (
          // password page
          <div className="adminInner">
            <h1>Enter Admin Code:</h1>
            <input
              type="password"
              name="admin"
              id="admin"
              value={adminPassword}
              onChange={(event) => setAdminPassword(event.target.value)}
            />
            <button
              className="btn btn-primary btn-sm m-3"
              onClick={submitPassword}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Admin;
