import React, { useState, useEffect } from "react";
import { Card } from ".";
import axios from "axios";
import { toast } from "react-toastify";
import { PayPalButton } from "react-paypal-button-v2";
import emailjs from "@emailjs/browser";

function Shop() {
  const [woodStyleOption, setWoodStyleOption] = useState([]);
  const [woodTypeOption, setWoodTypeOption] = useState([]);
  const domain = "https://rbodenwoodturning.com";
  //const domain = "http://localhost:3001";
  const [images, setImages] = useState([]);
  const [siftedImages, setSiftedImages] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const filterCards = (image) => {
    return (
      <>
        <Card
          image={image.filePath.base64}
          title={image.title}
          subtext={image.subtext}
          price={image.price}
          itemId={`Item #${image.itemId}`}
          sold={image.sold}
          button={
            <button
              className={`btn btn-primary btn-sm m-3 ${
                image.sold ? "disabled" : ""
              }`}
              data-toggle="modal"
              data-target="#editModal"
              href="#"
              onClick={() => setCurrentItem(image)}
            >
              Buy Now
            </button>
          }
        />
      </>
    );
  };

  useEffect(() => {
    axios
      .post(`${domain}/getShop`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.success) {
          setImages(res.data.shopItems);
          setSiftedImages(res.data.shopItems);
        }
      })
      .catch((res) => {
        new toast.error("Error with getting shop items");
      });
  }, []);

  useEffect(() => {
    getImages();
  }, [searchTerm]);

  const getImages = () => {
    return siftedImages
      .filter((image) => {
        if (searchTerm === "") {
          return image;
        }
        let showImage = false;
        Object.keys(image).map((imageKey) => {
          if (image[imageKey] === null) return;
          if (imageKey === "filePath") return;
          if (
            image[imageKey]
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            showImage = true;
          }
        });
        if (showImage) {
          return image;
        }
      })
      .map(filterCards);
  };

  return (
    <>
      {/* Start of modal */}
      <div
        className="modal fade"
        id="editModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-center"
                id="exampleModalLongTitle"
              >
                Purchase
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="card-block m-3 mb-4">
                  <img
                    className="card-img-top img-fluid"
                    // figure out why this is having issues
                    src={currentItem.filePath?.base64}
                    alt="shop item"
                  />
                  <h4 className="card-title mt-3">{currentItem.title}</h4>
                  <p className="card-text">{currentItem.subtext}</p>
                  <h5 className="text-muted float-right">
                    ${currentItem.price}
                  </h5>
                  <p className="card-text">Item #{currentItem.itemId}</p>
                </div>
                {
                  <PayPalButton
                    style={{
                      color: "blue",
                      shape: "pill",
                      label: "paypal",
                    }}
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              currency_code: "USD",
                              value: currentItem.price,
                            },
                          },
                        ],
                      });
                    }}
                    onApprove={(data, actions) => {
                      // send email here
                      const templateParams = {
                        itemId: currentItem.itemId,
                        orderId: data.orderID,
                        payerId: data.payerID,
                      };
                      emailjs
                        .send(
                          "service_x9ept8b",
                          "template_7y3klxj",
                          templateParams,
                          "tMxe41DWv9MQKUEnI"
                        )
                        .then(
                          (response) => {
                            new toast.success("Purchase was successful");
                            // mark item as sold
                            // give old item and new item
                            axios
                              .post(`${domain}/editItem`, {
                                headers: { "Content-Type": "application/json" },
                                editedItem: currentItem,
                                title: currentItem.title,
                                filePath: currentItem.filePath,
                                location: "Shop",
                                sold: true,
                              })
                              .then((res) => {
                                if (res.data.success) {
                                  new toast.success(
                                    currentItem.title + " was bought"
                                  );
                                }
                              })
                              .catch((res) => {
                                new toast.error(
                                  "Error with updating item info"
                                );
                              });
                          },
                          (err) => {
                            new toast.error(
                              "There was an error with processing your request. If this error persists please contact rbodenwoodturning@gmail.com"
                            );
                          }
                        );

                      return actions.order
                        .capture()
                        .then(function (details) {});
                    }}
                    options={{
                      clientId:
                        //temp id
                        //"AUYJGTeTG5ztaUiTQk-aKu0TyoARHAwALyCDciZZrgEf2ASkBGtvWlZWF7KfKKnTLsYMdlGejlQ6FUvp",
                        //real id
                        "ASrnhzFt7gCoekeUtNE7N96oJMf9EG-cHkp64fkpd5jM1pn7ij5EoLe3lC828jtyX1RUGxgVp3I_Dg0K",
                    }}
                  />
                }
              </div>
            </div>
            <div className="modal-footer d-flex">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="shop mt-4">
        <div className="container">
          <div className=" row mt-4">
            <h1>Shop</h1>
            <div className="col">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="row ">{getImages()}</div>
        </div>
      </div>
    </>
  );
}

export default Shop;
