import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, About, Home, PageNotFound, ContactMe, Shop, Gallery, Admin } from "./pages";

function App() {
  
  return (
    <div className="App bgColor">
      <Router>
        <Navigation />
          <Switch>
            <Route path="/" exact component={(Navigation) => <Home />} />
            <Route path="/contactMe" exact component={() => <ContactMe/>} />
            <Route path="/shop" exact component={() => <Shop />} />
            <Route path="/gallery" exact component={() => <Gallery />} />
            <Route path="/about" exact component={() => <About />} />
            <Route path="/admin" exact component={() => <Admin />} />
            <Route component={PageNotFound} />
          </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
