import React from "react";

function About() {
  let image = require("../americanAssociation.JPG");
  let boden = require("../boden.png");
  return (
    <div className="about">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="container">
              <h1 class="m-4">Info About Me</h1>

              <div class="row">
                <div class="col-md-8"></div>

                <div class="">
                  <p>
                    I have always admired the natural beauty of wood. I began
                    wood turning after receiving a wooden pen my son had made on
                    his lathe. After seeing the beautiful pen I decided I wanted
                    to learn to turn things of wood but wanted to make more
                    items than pens. I bought my first lathe on eBay in about
                    2006. I bought some wood turning gouges and thought I was
                    ready to go. I soon realized I had no idea what I was doing.{" "}
                    <br /> <br />
                    After taking some classes, and a lot of trial and error, I
                    was hooked. I soon realized the lathe I had originally
                    purchased was not the right kind of lathe to accomplish my
                    goal. I purchased a new lathe and proper tools and began
                    turning. I have attended various wood turning club meetings
                    and all-day demos by professional woodturners to learn from
                    others. I have continually pushed to improve my techniques
                    and abilities to learn new methods in woodturning. I enjoy
                    turning bowls, lidded boxes, goblets, vases, and platters.
                    <br /> <br />I am a member of the American Association of
                    Woodturners and also the Arizona Woodturners Association. I
                    have been able to display my work in the Arts Prescott
                    Gallery in Prescott, AZ. I have created this website to
                    display some of my past work and also offer pieces for sale.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-auto mt-4 mb-4">
            <div className="mt-4"></div>
            <div className="container float-right col">
              <div className="mb-4">
                <img src={boden.default} alt="" width="200" height="200" />
              </div>
              <div className="mt-4">
                <img src={image.default} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
