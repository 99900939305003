import React from "react";

function SingleCard(props) {
  return (
    <div class="carousel-item">
      <div class="row">
        <div class="col-md-4 mb-3">
            <div class="card">
              <img class="img-fluid" alt="100%x280" src={props.image1} />
              <div class="card-body">
                <h4 class="card-title">{props.title1}</h4>
                <p class="card-text">{props.subtext1}</p>
              </div>
            </div>
        </div>

        <div class="col-md-4 mb-3">
            <div class="card">
              <img class="img-fluid" alt="100%x280" src={props.image2} />
              <div class="card-body">
                <h4 class="card-title">{props.title2}</h4>
                <p class="card-text">{props.subtext2}</p>
              </div>
            </div>
        </div>

        <div class="col-md-4 mb-3">
            <div class="card">
              <img class="img-fluid" alt="100%x280" src={props.image3} />
              <div class="card-body">
                <h4 class="card-title">{props.title3}</h4>
                <p class="card-text">{props.subtext3}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCard;
