import { React } from "react";
import { Link } from "react-router-dom";
import ActionBanner from "../components/ActionBanner";
import CardCarousel from "../components/CardCarousel";
import HeroBanner from "../components/HeroBanner";

function Home() {
  return (
    <div className="home">
      <HeroBanner
        title="Rich Boden Wood Turning"
        subtext="I am pleased to showcase items I have created since I began turning wood. The gallery section highlights the different types of forms I create in wood – bowls, goblets, platters, and lidded boxes - and also the different varieties of wood used. Soon there will also be items available in my store for purchase from this website."
        image1={"images/Aspengoblet.jpeg"}
        image2={"images/NaturalEdge.jpeg"}
        image3={"images/ElmVase.jpeg"}
        image4={"images/ElmCedarbox.jpeg"}
        primaryBtn={
          <Link className="btn btn-primary btn-lg px-4 me-md-3" to="/shop">
            Shop Now
          </Link>
        }
        secondaryBtn={
          <Link
            className="btn btn-outline-secondary btn-lg px-4 me-md-3"
            to="/contactMe"
          >
            Contact Me
          </Link>
        }
      />
      <CardCarousel
        carouselTitle="Gallery"
        title1="Goblets"
        image1={"images/Aspengoblet.jpeg"}
        title2="Lidded Boxes"
        image2={"images/Banksiabox.jpeg"}
        title3="Bowls"
        image3={"images/SpaultedMango.JPG"}
      />
      <ActionBanner
        headerText="Browse The Shop"
        headerLine={true}
        subText="Browse through multiple selections of wood and styles"
        button={
          <Link className="btn btn-primary btn-lg px-4 me-md-3" to="/shop">
            Shop Now
          </Link>
        }
      />
    </div>
  );
}

export default Home;
