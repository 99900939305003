import React from "react";
import SingleCard from "./SingleCard";
import { Link } from "react-router-dom";

function CardCarousel(props) {
  return (
    <section class="pt-5 pb-5">
      <h1 class="mb-3 text-center">{props.carouselTitle}</h1>
      <div class="mb-3 text-center">
        <a
          class="btn btn-primary mr-3"
          href="#carouselExampleIndicators2"
          role="button"
          data-slide="prev"
        >
          <i class="fa fa-arrow-left"></i>
        </a>
        <Link class="btn btn-primary btn-lg px-4" to="/gallery" onClick={() => window.scrollTo(0, 0)}>
            Visit Gallery
          </Link>
        <a
          class="btn btn-primary ml-3"
          href="#carouselExampleIndicators2"
          role="button"
          data-slide="next"
        >
          <i class="fa fa-arrow-right"></i>
        </a>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              id="carouselExampleIndicators2"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                        <div class="card">
                          <img
                            class="img-fluid"
                            alt="100%x280"
                            src={props.image1}
                          />
                          <div class="card-body">
                            <h4 class="card-title">{props.title1}</h4>
                            <p class="card-text">{props.subtext1}</p>
                          </div>
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <div class="card">
                          <img
                            class="img-fluid"
                            alt="100%x280"
                            src={props.image2}
                          />
                          <div class="card-body">
                            <h4 class="card-title">{props.title2}</h4>
                            <p class="card-text">{props.subtext2}</p>
                          </div>
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <div class="card">
                          <img
                            class="img-fluid"
                            alt="100%x280"
                            src={props.image3}
                          />
                          <div class="card-body">
                            <h4 class="card-title">{props.title3}</h4>
                            <p class="card-text">{props.subtext3}</p>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                {/* Figure out how to make this data dynamic */}
                <SingleCard
                  title1="Platters"
                  image1={"images/CherryPlatter.JPG"}
                  title2="Vases"
                  image2={"images/ElmVase.jpeg"}
                  title3="Natural Edge Bowls"
                  image3={"images/NatElm.jpeg"}
                  // add props here
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardCarousel;
