import React from "react";

function Card(props) {
  return (
    <>
      <div className="col-md-6 col-lg-3 col-xl-4 py-2">
        <div className="card h-100 item">
          <img
            className="card-img-top img-fluid"
            src={props.image}
            alt="shop item"
          />
          <div className="card-block m-3">
            {props.sold ? (
              <div className="text-danger" id="watermark">
                {props.sold ? "S O L D" : " "}
              </div>
            ) : (
                <div className="mb-4"></div>
            )}

            <h4 className="card-title">{props.title}</h4>
            <p className="card-text">{props.subtext}</p>
            <h5 className="text-muted float-right">
              {props.price ? `$${props.price}` : ""}
            </h5>
            <p className="card-text text-muted">{props.itemId}</p>
          </div>
          {props.button}
        </div>
      </div>
    </>
  );
}

export default Card;
