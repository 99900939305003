import React, { useState, useEffect } from "react";
import { Card } from ".";
import axios from "axios";
import { toast } from "react-toastify";

function Gallery() {
  const domain = "https://rbodenwoodturning.com";
  //const domain = "http://localhost:3001";
  const [bowls, setBowls] = useState([]);
  const [goblets, setGoblets] = useState([]);
  const [liddedBoxes, setLiddedBoxes] = useState([]);
  const [naturalBowls, setNaturalBowl] = useState([]);
  const [platters, setPlatter] = useState([]);
  const [vases, setVases] = useState([]);
  const [misc, setMisc] = useState([]);
  const [isLoadedBowl, setIsLoadedBowl] = useState(false);
  const [isLoadedGoblet, setIsLoadedGoblet] = useState(false);
  const [isLoadedLidded, setIsLoadedLidded] = useState(false);
  const [isLoadedNatural, setIsLoadedNatural] = useState(false);
  const [isLoadedPlatter, setIsLoadedPlatter] = useState(false);
  const [isLoadedVase, setIsLoadedVase] = useState(false);
  const [isLoadedMisc, setIsLoadedMisc] = useState(false);

  const filterCards = (image) => {
    return (
      <Card
        image={image.filePath.base64}
        title={image.title}
        subtext={image.subtext}
      />
    );
  };

  useEffect(() => {
    axios
      .post(`${domain}/getSiteData`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.success) {
          let style = res.data.items;
          for (let i = 0; i < 7; i++) {
            axios
              .post(`${domain}/getGallery`, {
                headers: { "Content-Type": "application/json" },
                style: style[0].styles[i],
              })
              .then((res) => {
                if (res.data.success) {
                  switch (style[0].styles[i]) {
                    case "Bowl":
                      setIsLoadedBowl(true);
                      setBowls(res.data.galleryItems);
                      break;
                    case "Goblet":
                      setIsLoadedGoblet(true);
                      setGoblets(res.data.galleryItems);
                      break;
                    case "Lidded Box":
                      setIsLoadedLidded(true);
                      setLiddedBoxes(res.data.galleryItems);
                      break;
                    case "Natural Edge Bowl":
                      setIsLoadedNatural(true);
                      setNaturalBowl(res.data.galleryItems);
                      break;
                    case "Platter":
                      setIsLoadedPlatter(true);
                      setPlatter(res.data.galleryItems);
                      break;
                    case "Vase":
                      setIsLoadedVase(true);
                      setVases(res.data.galleryItems);
                      break;
                    case "Misc.":
                      setIsLoadedMisc(true);
                      setMisc(res.data.galleryItems);
                      break;
                    default:
                      break;
                  }
                }
              })
              .catch((res) => {
                new toast.error("Error with getting gallery");
              });
          }
        }
      })
      .catch((res) => {
        new toast.error("Error with getting site data");
      });
  }, []);

  return (
    <>
      <div className="gallery text-center">
        <h1 className="text-center">Gallery of Previous Work</h1>
        <div className="m-4">
          <a className="btn btn-primary btn-lg px-4 me-md-3 m-2" href="#bowls">
            Bowls
          </a>
          <a
            className="btn btn-primary btn-lg px-4 me-md-3 m-2"
            href="#goblets"
          >
            Goblets
          </a>
          <a
            className="btn btn-primary btn-lg px-4 me-md-3 m-2"
            href="#liddedBoxes"
          >
            Lidded Boxes
          </a>
          <a
            className="btn btn-primary btn-lg px-4 me-md-3 m-2"
            href="#naturalEdgeBowls"
          >
            Natural Edge Bowls
          </a>
          <a
            className="btn btn-primary btn-lg px-4 me-md-3 m-2"
            href="#platters"
          >
            Platters
          </a>
          <a className="btn btn-primary btn-lg px-4 me-md-3 m-2" href="#vases">
            Vases
          </a>
          <a className="btn btn-primary btn-lg px-4 me-md-3 m-2" href="#misc">
            Misc.
          </a>
        </div>
        {/* Here is the content on the right side */}
        {/* Make this come from a mapped function */}
        <div className="container ">
          <h1>Bowls</h1>
          <span className="anchor" id="bowls"></span>
          <div className="row justify-content-md-center">
            {isLoadedBowl ? (
              bowls.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="container ">
          <h1>Goblets</h1>
          <span className="anchor" id="goblets"></span>
          <div className="row justify-content-md-center">
            {isLoadedGoblet ? (
              goblets.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="container ">
          <h1>Lidded Boxes</h1>
          <span className="anchor" id="liddedBoxes"></span>

          <div className="row justify-content-md-center">
            {isLoadedLidded ? (
              liddedBoxes.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="container ">
          <h1>Natural Edge Bowls</h1>
          <span className="anchor" id="naturalEdgeBowls"></span>
          <div className="row justify-content-md-center">
            {isLoadedNatural ? (
              naturalBowls.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="container ">
          <h1>Platters</h1>
          <span className="anchor" id="platters"></span>
          <div className="row justify-content-md-center">
            {isLoadedPlatter ? (
              platters.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="container ">
          <h1>Vases</h1>
          <span className="anchor" id="vases"></span>
          <div className="row justify-content-md-center">
            {isLoadedVase ? (
              vases.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="container ">
          <h1>Misc.</h1>
          <span className="anchor" id="misc"></span>
          <div className="row justify-content-md-center">
            {isLoadedMisc ? (
              misc.map(filterCards)
            ) : (
              <>
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <div className="position-fixed mr-4 mb-4 bottom-0 end-0">
          <button
            className="btn btn-primary"
            onClick={() => window.scrollTo(0, 0)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-arrow-up-circle"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
              />
            </svg>
            <br />
            Back to Top
          </button>
        </div>
      </div>
    </>
  );
}

export default Gallery;
