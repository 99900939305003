import { React } from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  let image = require("../logo.jpg");
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand-sm navbar-light bg-light fixed-top">
        <a href="/">
          <img
            className="navbar-brand logo"
            src={image.default}
            alt="logo"
            width="400"
            height="500"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navList">
            <li
              className={`nav-item ${
                props.location.pathname === "/gallery" ? "selected" : ""
              }`}
            >
              <Link
                className="nav-link"
                id="navBarItem"
                to="/gallery"
                onClick={() => window.scrollTo(0, 0)}
              >
                <b>Gallery</b>
              </Link>
            </li>
            <li
              className={`nav-item ${
                props.location.pathname === "/shop" ? "selected" : ""
              }`}
            >
              <Link
                className="nav-link"
                id="navBarItem"
                to="/shop"
                onClick={() => window.scrollTo(0, 0)}
              >
                <b>Shop</b>
              </Link>
            </li>

            <li
              className={`nav-item ${
                props.location.pathname === "/about" ? "selected" : ""
              }`}
            >
              <Link
                className="nav-link"
                id="navBarItem"
                to="/about"
                onClick={() => window.scrollTo(0, 0)}
              >
                <b>About</b>
              </Link>
            </li>
            <li
              className={`nav-item btn-primary rounded ${
                props.location.pathname === "/contactMe" ? "selected" : ""
              }`}
            >
              <Link
                className="nav-link text-white"
                id="navBarItem"
                to="/contactMe"
              >
                Contact Me
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navigation);
