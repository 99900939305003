import React from "react";

function HeroBanner(props) {
  return (
    <div>
      <div class="container col-md-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6 grid">
              <div class="row mb-4">
                <div class="col">
                  <img
                    src={props.image1}
                    class="d-block img-fluid col"
                    alt="Bootstrap Themes"
                    maxwidth="400"
                    maxheight="300"
                    loading="lazy"
                  />
                </div>
                <div class="col">
                  <img
                    src={props.image2}
                    class="d-block img-fluid col"
                    alt="Bootstrap Themes"
                    maxwidth="400"
                    maxheight="300"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <img
                    src={props.image3}
                    class="d-block img-fluid col"
                    alt="Bootstrap Themes"
                    maxwidth="400"
                    maxheight="300"
                    loading="lazy"
                  />
                </div>
                <div class="col">
                  <img
                    src={props.image4}
                    class="d-block img-fluid col"
                    alt="Bootstrap Themes"
                    maxwidth="400"
                    maxheight="300"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>

          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3 heroSubText">
              {props.title}
            </h1>
            <p class="lead heroSubText">{props.subtext}</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              {props.primaryBtn}
              {props.secondaryBtn}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
